<template>
  <div class="Review">
    <div class="Review-main">
      <div class="dw-card2">
        <div class="title">{{ $t("lang.QText13") }}</div>
        <div class="main">
          <div class="Review-item-data">
            <span v-if="form.fromType == 0 && form.fromCyPort">{{
              form.fromCyPortName
            }}</span>
            <span v-if="form.fromType == 1 && form.fromDoorAddress"
              >{{ form.fromDoorAddress }},</span
            >
            <span v-if="form.fromType == 1 && form.fromDoorCity"
              >{{ form.fromDoorCityName }},</span
            >
            <span v-if="form.fromType == 1 && form.fromDoorState"
              >{{ form.fromDoorStateName }},</span
            ><span v-if="form.fromType == 1 && form.fromDoorCountry"
              >{{ form.fromDoorCountryName }},</span
            >
            <span v-if="form.fromType == 1 && form.fromDoorZipCode">{{
              form.fromDoorZipCode
            }}</span>
          </div>
          <div class="item2">
            <div class="item2-title2">{{ $t("lang.QText21") }}：</div>
            <div
              class="item2-content"
              v-if="$i18n.locale == 'zh_cn' && form.cargoReadyDate !== ''"
            >
              {{ form.cargoReadyDate.slice(0, 10) }}
            </div>
            <div
              class="item2-content"
              v-if="$i18n.locale == 'en_us' && form.cargoReadyDate !== ''"
            >
              {{ utils.zhDate_to_enDate(form.cargoReadyDate).slice(0, 15) }}
            </div>
          </div>
          <div
            class="Review-item-title"
            v-if="
              form.originPickUpFlag == 1 ||
              form.originCustomsClearFlag == 1 ||
              form.originInsurFlag == 1 ||
              form.originBondFlag == 1 ||
              form.originIsfFlag == 1 ||
              form.originAmsFlag == 1 ||
              form.originAciFlag == 1 ||
              form.originDocFlag == 1 ||
              form.originWareFlag == 1
            "
          >
            {{ $t("lang.BText79") }}
          </div>
          <div class="noServices" v-else>{{ $t("lang.NText193") }}</div>
          <div class="Review-item-data">
            <div v-if="form.originPickUpFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText49") }}
              </div>
            </div>

            <div v-if="form.originCustomsClearFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.BText25") }}
              </div>
            </div>

            <div v-if="form.originInsurFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText57") }}
              </div>
              <div class="line-value">
                {{ form.originInsurCurr }} {{ form.originInsurVlaue }}
              </div>
            </div>

            <div v-if="form.originBondFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText69") }}
              </div>
              <div class="line-value">
                {{ form.originBondType == 1 ? "Annual bond" : "Single bond" }}
              </div>
            </div>

            <div v-if="form.originIsfFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                ISF
              </div>
            </div>

            <div v-if="form.originAmsFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                AMS
              </div>
            </div>

            <div v-if="form.originAciFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                ACI
              </div>
            </div>

            <div v-if="form.originDocFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText61") }}
              </div>
            </div>

            <div v-if="form.originWareFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText63") }}
              </div>
              <div class="line-value">
                {{ form.originWareServices }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dw-card2">
        <div class="title">{{ $t("lang.QText14") }}</div>
        <div class="main">
          <div class="Review-item-data">
            <span v-if="form.toType == 0 && form.toCyPort">{{
              form.toCyPortName
            }}</span>
            <span v-if="form.toType == 1 && form.toDoorAddress"
              >{{ form.toDoorAddress }},</span
            >
            <span v-if="form.toType == 1 && form.toDoorCity"
              >{{ form.toDoorCityName }},</span
            >
            <span v-if="form.toType == 1 && form.toDoorState"
              >{{ form.toDoorStateName }},</span
            >
            <span v-if="form.toType == 1 && form.toDoorCountry"
              >{{ form.toDoorCountryName }},</span
            ><span v-if="form.toType == 1 && form.toDoorZipCode">{{
              form.toDoorZipCode
            }}</span>
          </div>
          <div class="item2">
            <div class="item2-title2">{{ $t("lang.BText28") }}：</div>
            <div
              class="item2-content"
              v-if="$i18n.locale == 'zh_cn' && form.deliveryDate !== ''"
            >
              {{ form.deliveryDate.slice(0, 10) }}
            </div>
            <div
              class="item2-content"
              v-if="$i18n.locale == 'en_us' && form.deliveryDate !== ''"
            >
              {{ utils.zhDate_to_enDate(form.deliveryDate).slice(0, 15) }}
            </div>
          </div>
          <div
            class="Review-item-title"
            v-if="
              form.destinationDelivFlag == 1 ||
              form.destinationCustomsClearFlag == 1 ||
              form.destinationBondFlag == 1 ||
              form.destinationWareFlag == 1
            "
          >
            {{ $t("lang.BText79") }}
          </div>
          <div class="noServices" v-else>{{ $t("lang.NText193") }}</div>
          <div class="Review-item-data">
            <div v-if="form.destinationDelivFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText67") }}
              </div>
            </div>

            <div v-if="form.destinationCustomsClearFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.BText25") }}
              </div>
            </div>

            <div v-if="form.destinationBondFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText69") }}
              </div>
              <div class="line-value">
                <span v-if="form.destinationBondType == 0">{{
                  $t("lang.QText70")
                }}</span>
                <span v-if="form.destinationBondType == 1">{{
                  $t("lang.QText71")
                }}</span>
              </div>
            </div>

            <div v-if="form.destinationWareFlag == 1">
              <div class="line-key">
                <div class="line-key-icon">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.QText63") }}
              </div>
              <div class="line-value">
                {{ form.destinationWareServices }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dw-card2">
        <div class="title">{{ $t("lang.BText29") }}</div>
        <div class="main">
          <div class="Review-item-data">
            <div
              class="containerSize"
              v-for="item in form.containerDatas"
              :key="item.containerSize"
            >
              <div class="ft">{{ item.containerSize }} x {{ item.count }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="dw-card2">
        <div class="title">{{ $t("lang.NText88") }}</div>
        <div class="main">
          <div class="item2 Review-item-data" v-if="form.cargoTypeName">
            <div class="item2-title2">{{ $t("lang.QText43") }}：</div>
            <div class="item2-content">{{ form.cargoTypeName }}</div>
          </div>
          <div class="item2 Review-item-data" v-if="form.commodityHscodesName">
            <div class="item2-title2">{{ $t("lang.QText47") }}：</div>
            <div class="item2-content">{{ form.commodityHscodesName }}</div>
          </div>
          <div class="item2 Review-item-data" v-if="form.totalWeight">
            <div class="item2-title2">{{ $t("lang.QText28") }}：</div>
            <div class="item2-content">
              <div class="number">{{ form.totalWeight }}</div>
              <div class="unit">
                {{ form.totalWeightUnit }}
              </div>
            </div>
          </div>
          <div class="item2 Review-item-data" v-if="form.totalVolume">
            <div class="item2-title2">{{ $t("lang.QText29") }}：</div>
            <div class="item2-content">
              <div class="number">{{ form.totalVolume }}</div>
              <div class="unit">{{ form.totalVolumeUnit }}</div>
            </div>
          </div>
          <div class="item2 Review-item-data" v-if="form.totalQuantity">
            <div class="item2-title2">{{ $t("lang.QText30") }}：</div>
            <div class="item2-content">
              <div class="number">
                {{ form.totalQuantity }}
              </div>
              <div class="unit">{{ form.totalQuantitUnit }}</div>
            </div>
          </div>
          <div
            class="item2 Review-item-data"
            v-if="form.dangerousGoodsType && form.dangerousGoodsType.length > 0"
          >
            <div class="item2-title2">{{ $t("lang.BText30") }}：</div>
            <div class="item2-content">
              <div
                class="Dangerous"
                v-for="item in form.dangerousGoodsType"
                :key="item.containerSize"
              >
                <div v-if="item == 0">
                  <div class="line-key">
                    <div class="line-key-icon">
                      <svg-icon icon-class="yes"></svg-icon>
                    </div>
                    {{ $t("lang.QText32") }}
                  </div>
                </div>
                <div v-if="item == 1">
                  <div class="line-key">
                    <div class="line-key-icon">
                      <svg-icon icon-class="yes"></svg-icon>
                    </div>
                    {{ $t("lang.QText33") }}
                  </div>
                </div>
                <div v-if="item == 2">
                  <div class="line-key">
                    <div class="line-key-icon">
                      <svg-icon icon-class="yes"></svg-icon>
                    </div>
                    {{ $t("lang.QText34") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dw-card2">
        <div class="title">{{ $t("lang.QText38") }}</div>
        <div class="main">
          <div class="Review-item-data">
            <span v-show="form.incotermsType !== '' && form.incotermsType == 0"
              >{{ $t("lang.QText40") }}：</span
            ><span v-show="form.incotermsType !== '' && form.incotermsType == 1"
              >{{ $t("lang.QText41") }}：</span
            >{{ form.incotermsDataType || "No select" }}
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-button" v-if="!templateFlag && showButton">
      <template v-if="!form.id">
        <dw-button
          type="successSmall"
          v-if="!editTemplateFlag"
          @click="showSaveTemplate = true"
          >{{ $t("lang.QText78") }}</dw-button
        >
        <dw-button type="infoSmall" @click="Edit()">{{
          $t("lang.QText77")
        }}</dw-button>
      </template>

      <dw-button type="infoSmall" @click="Edit()">{{
        $t("lang.BText97")
      }}</dw-button>
      <dw-button
        type="primarySmall"
        :loading="submitLoading"
        @click="Submit()"
        >{{ $t("lang.text14") }}</dw-button
      >
    </div>
    <dw-check-box
      :showCheckBox="showSaveTemplate"
      @Cancel="showSaveTemplate = false"
      @Save="Submit(1)"
      :primaryButton="$t('lang.SText13')"
      :CancelButton="$t('lang.QText79')"
    >
      {{ $t("lang.QText92") }} *
      <el-form :model="form">
        <el-form-item
          class="form-item"
          prop="templateName"
          label=""
          :rules="[
            {
              required: true,
              message: $t('lang.SText143'),
            },
            { max: 20, message: $t('lang.SText154') },
          ]"
        >
          <el-input v-model="form.quotationTemplateName"></el-input>
        </el-form-item>
      </el-form>
    </dw-check-box>
  </div>
</template>

<script>
import DwCheckBox from "../../../components/dwCheckBox/dwCheckBox.vue";
import dwTimeLine from "../../../components/dwTimeline/dwTimeLine.vue";
import Template from "../../Booking/template.vue";
import utils from "../../../utils/util";

export default {
  components: { dwTimeLine, DwCheckBox, Template },
  props: {
    quotationData: {
      type: String,
      default: "",
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      step: 4,
      stepList: [
        {
          name: "From - To",
          zh_name: "起始-到达",
        },
        {
          name: "Date",
          zh_name: "日期",
        },
        {
          name: "Cargo info",
          zh_name: "货物信息",
        },
        {
          name: "Details & services",
          zh_name: "详细信息 & 服务",
        },
      ],
      form: {
        dangerousGoodsType: [],
      },
      showCancel: false,
      showSaveTemplate: false,
      templateFlag: false,
      quotationTypeObj: {},
      editTemplateFlag: false,
      utils: utils,
      submitLoading: false,
    };
  },
  created() {
    this.form = this.$route.params.submitForm;
    this.templateFlag = this.$route.params.templateFlag;
    this.editTemplateFlag = this.$route.params.editTemplateFlag;
    if (this.quotationData) {
      this.form = JSON.parse(this.quotationData);
    }
    // this.form = this.quotationData;
    this.$session("quotationType").get((value) => {
      value.forEach((item) => {
        this.quotationTypeObj[item.dictValue] = item.dictLabel;
        if (item.children.length > 0) {
          item.children.forEach((item2) => {
            this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
          });
        }
      });
    });
  },
  methods: {
    Edit() {
      this.$emit("edit");
    },
    Cancel() {
      this.$emit("cancelReview");
    },
    SaveAsTemplate() {},
    Submit(e) {
      let that = this;
      that.$store.commit("api/SET_LOADING", true);
      let submitForm = { ...{}, ...that.form };
      if (typeof submitForm.containerDatas == "object") {
        submitForm.containerDatas = JSON.stringify(submitForm.containerDatas);
      }
      if (typeof submitForm.dangerousGoodsType == "object") {
        submitForm.dangerousGoodsType = JSON.stringify(
          submitForm.dangerousGoodsType
        );
      }
      submitForm.services = [];
      submitForm.taskLogs = [];
      if (e == 1) {
        submitForm.quotationTemplateFlag = 1;
      }
      this.submitLoading = true;

      // if (this.$i18n.locale == "en_us") {
      //   submitForm.deliveryDate = utils.enDate_to_zhDate(
      //     this.form.deliveryDate
      //   );
      //   submitForm.cargoReadyDate = utils.enDate_to_zhDate(
      //     this.form.cargoReadyDate
      //   );
      // }
      that.$ajax
        .cPost(that.source.quotation_api.saveQuotation, submitForm)
        .then((res) => {
          if (res.data.result == "true") {
            that.$notify.success({
              title: res.data.message,
              offset: 100,
            });
          } else {
            that.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
          this.$store.commit("api/SET_LOADING", false);
          if (e) {
            this.showSaveTemplate = false;
            that.form.quotationTemplateFlag = 0;
          } else if (that.form.quotationTemplateFlag == 1) {
            this.$router.push("/Quotation/quotationTemplate");
          } else {
            let obj = JSON.parse(sessionStorage.getItem("@menuList")).find(
              (res) => {
                return res.menuName == "My quotation";
              }
            );
            sessionStorage.setItem("menu", JSON.stringify(obj));
            this.$router.push("/Quotation/quotation");
          }
          this.submitLoading = false;
          this.$emit("SaveSuccess");
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.Review {
  width: 900px;
  text-align: left;
  overflow: hidden;
  padding-bottom: 100px;
  margin: auto;
  .timeline {
    background-color: $background1;
    padding: 20px 30px;
    border: 1px solid $borderColor1;
  }
  .Review-title {
    font-size: 60px;
    color: $mainColor;
    text-align: left;
    padding: 25px 30px;
    display: flex;
    font-weight: bold;
  }
  .dw-card2 {
    margin-bottom: 15px;
    .title {
      font-family: "SEMIBOLD";
    }
    .main {
      margin-top: 10px;
    }
  }
  .Review-main {
    width: 900px;
    color: $mainColor;
    line-height: 30px;
    font-size: 18px;

    .Review-item-title {
      border-bottom: 2px solid $fontColor1;
      margin-top: 20px;
      padding-bottom: 5px;
      font-size: 20px;
      color: #999999;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      font-family: "SEMIBOLD";
      width: 450px;

      .date {
        font-size: 18px;
        line-height: 60px;
      }
    }

    .noServices {
      font-size: 20px;
      color: #c2c2c2;
      margin-top: 20px;
    }
    .Review-item-data {
      font-size: 18px;
      margin: 5px 0;
    }
    .line-value {
      color: $background2;
      margin-left: 30px;
    }
    .title48 {
      width: 48%;
    }
    .item2 {
      display: flex;
      font-size: 18px;
      .item2-title2 {
        color: $fontColor2;
        white-space: nowrap;
        margin-right: 10px;
        width: 180px;
      }
      .item2-content {
        display: flex;
        color: $mainColor;
        .number {
          width: 100px;
          text-align: right;
        }
        .unit {
          color: $background2;
          margin-left: 5px;
        }
      }
    }

    .containerSize {
      color: $mainColor;
      font-size: 18px;
      display: flex;
      width: 200px;
      line-height: 30px;
      .ft {
        width: 100px;
      }
      .num {
        width: 100px;
      }
    }

    .Review-item-line {
      display: flex;
      justify-content: space-between;
    }
    .line-key {
      display: flex;
      flex: 3;
      width: 100%;
      height: 30px;
      line-height: 30px;
      margin-right: 20px;
      .line-key-icon {
        height: 16px;
        width: 16px;
        background: $mainColor;
        color: #fff;
        margin: 6px 12px 6px 0;
        display: flex;
        justify-content: center;
        .svg-icon {
          width: 10px;
          height: 10px;
          margin: auto;
        }
      }
    }
    .line-value {
      flex: 7;
      margin-right: 20px;
    }
  }
  .bottom-button {
    display: flex;
    padding: 60px 0;
    .el-button {
      margin-right: 20px;
    }
  }
}
</style>